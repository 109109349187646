import styles from './css/main.css';
// console.log(styles);
// import { _ } from 'lodash';
import Environment from './environment';
import modelMaping from './modelMaping';

var THREE = require('three');
const Stats = require('stats-js');

var stats;
var helper = false;


const materials = {
	shadow: new THREE.MeshBasicMaterial({
		color: 0x000000,
		side: THREE.DoubleSide,
		// alphaMap: textureShadow,
		transparent: true,
		// depthWrite: false,
		opacity: THREE.Math.lerp(1, 0.25, 0.5),
	}),
	white1: new THREE.MeshPhongMaterial({
		color: 0xffffff,
		shininess: 500,
		reflectivity: 0.8
	}),
	white2: new THREE.MeshPhongMaterial({
		color: 0xffffff,
		shininess: 500,
		reflectivity: 0.8
	}),
};



window.onload = function () {

	const parameters = {
		distance: 400,
		hpoint: 0,
		rotate: true,
		center: 0.5,
	};


	const canvasContainer = document.getElementById('canvasContainer');
	const canvasContainerLoading = document.getElementById('canvasContainer-loading');

	const environment = new Environment(canvasContainer, parameters);
	const myModel = new modelMaping(environment, materials, 'assets/kubek4.obj', canvasContainerLoading);

	const stats = new Stats();
	const { container } = environment;
	container.appendChild(stats.dom);

	const animation = () => {
		if (!myModel.loading) {
			environment.render();
		}
		stats.update();
		requestAnimationFrame(animation)
	}
	animation();

	// const changeModel = document.getElementById('changeModel');
	// if (changeModel)
	// 	changeModel.addEventListener('change', (event) => {
	// 		console.log(select.value);
	// 		myModel.setModel(select.value)
	// 	});

	const select = document.getElementById('changeTexture');
	if (select)
		select.addEventListener('change', (event) => {
			// console.log(select.value);
			myModel.setTexture(select.value)
		});

	const fileInput = document.getElementById('fileInput');

	canvasContainer.addEventListener(
		'dragover',
		event => {
			event.preventDefault();
			event.dataTransfer.dropEffect = 'copy';
		},
		false,
	);

	canvasContainer.addEventListener(
		'dragenter',
		() => {
			canvasContainer.style.opacity = 0.5;
		},
		false,
	);
	canvasContainer.addEventListener(
		'dragleave',
		() => {
			canvasContainer.style.opacity = 1;
		},
		false,
	);

	canvasContainer.addEventListener(
		'drop',
		(event) => {
			event.preventDefault();
			var reader = new FileReader();
			reader.addEventListener(
				'load',
				function (event) {
					myModel.setTexture(event.target.result)
				},
				false,
			);
			reader.readAsDataURL(event.dataTransfer.files[0]);
			canvasContainer.style.opacity = 1;
		},
		false,
	);


	const toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});


	fileInput.addEventListener(
		'change',
		event => {
			const file = fileInput.files[0];
			toBase64(file).then(
				file => {
					myModel.setTexture(file)
				}
			)

		},
		false
	);



	const saveFile = (strData, filename) => {

		var link = document.createElement('a');
		if (typeof link.download === 'string') {
			document.body.appendChild(link); //Firefox requires the link to be in the body
			link.download = filename;
			link.href = strData;
			link.click();
			document.body.removeChild(link); //remove the link when done
		} else {
			location.replace(uri);
		}
	}
	const saveAsImage = (toFile) => {

		var strDownloadMime = 'image/octet-stream';
		var imgData, imgNode;

		try {
			var strMime = 'image/png';

			setTimeout(function () {
				imgData = '' + environment.renderer.domElement.toDataURL(strMime, 1.0) + '';

				if (toFile) {
					saveFile(imgData.replace(strMime, strDownloadMime), 'test.png');
				} else {
					const img = document.createElement('img');
					img.src = imgData;
					document.getElementById('info').appendChild(img)
				}

				return imgData;
			}, 100);
		} catch (e) {
			console.log(e);
			return;
		}
	}

	window.saveAsImage = saveAsImage;


};







